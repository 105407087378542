.ticker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 0 112px;
  min-height: 128px;
  width: 100%;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
  @media only screen and (max-width: 450px) {
    padding: 0 20px;
    min-height: 90px !important;
  }
}

.scroller {
  display: flex;
  align-items: center;
  gap: 48px;
  animation: scroller 20s linear normal forwards infinite;
}

@keyframes scroller {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(-1135px);
  }
}
